import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_PAECHTER } from '../Paechter/graphql';
import LoadingAnimation from '../Utils/LoadingAnimation';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 20,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30,
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 13,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0',
    },
    oddRow: {
        backgroundColor: '#ffffff',
    },
    cell: {
        padding: 2,
    },
    name: {
        width: '35%',
    },
    vorname: {
        width: '35%',
    },
    kontierungsnummer: {
        width: '30%',
    },
});

const Debitoren = () => {
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER);

    if (loading) return <LoadingAnimation />;

    // Calculate cutoff date: two months before today
    const today = new Date();
    const cutoffDate = new Date(today.setMonth(today.getMonth() - 2));

    const paechterList = data
        ? data.paechter
              .filter((item) => !item.name.toLowerCase().includes('mob3ls'))
              .map((item) => ({
                  name: item.name,
                  vorname: item.vorname,
                  // Filter pachtPaechter by (pachtende > cutoffDate or pachtende is null)
                  pachtPaechter: (item.pachtPaechter || []).filter((pacht) => {
                      const pachtendeDate = pacht.pachtende ? new Date(pacht.pachtende) : null;
                      return !pachtendeDate || pachtendeDate > cutoffDate;
                  }),
              }))
              // Exclude paechter with no valid pachtPaechter remaining after filtering
              .filter((item) => item.pachtPaechter.length > 0)
        : [];

    // Maintain a counter to alternate row colors consistently
    let rowCounter = 0;

    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Render table header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name</Text>
                            <Text style={[styles.cell, styles.header, styles.vorname]}>Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.kontierungsnummer]}>Kontierungsnummer</Text>
                        </View>

                        {/* Render rows */}
                        {paechterList.flatMap((item) =>
                            item.pachtPaechter.map((pacht, subIndex) => {
                                const rowStyle = rowCounter % 2 === 0 ? styles.evenRow : styles.oddRow;
                                const showNameAndVorname = subIndex === 0;
                                rowCounter++; // Increment the counter for each row

                                return (
                                    <View key={`${item.name}-${pacht.kontierungsnummer}-${rowCounter}`} style={[styles.row, rowStyle]}>
                                        <Text style={[styles.cell, styles.name]} wrap={false}>
                                            {showNameAndVorname ? item.name : ''}
                                        </Text>
                                        <Text style={[styles.cell, styles.vorname]} wrap={false}>
                                            {showNameAndVorname ? item.vorname : ''}
                                        </Text>
                                        <Text style={[styles.cell, styles.kontierungsnummer]} wrap={false}>
                                            {pacht.kontierungsnummer}
                                        </Text>
                                    </View>
                                );
                            })
                        )}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default Debitoren;
