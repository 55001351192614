import React from 'react';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Title from '../Utils/Title';

import { GET_PACHTSUMME_OF_YEAR, GET_ERSTATTUNG_PACHT_OF_YEAR } from './graphql';

import LoadingAnimation from '../Utils/LoadingAnimation';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';



// Custom Tooltip Pachtsummen
const CustomTooltipPachtsummen = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ background: 'white', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                <p>Summe Pachtrechnungen für {label}</p>
                {payload.map((entry, index) => {
                    let unit = 'EUR';

                    // Format value with thousands separator
                    const formattedValue = new Intl.NumberFormat('de-DE').format(entry.value);

                    return (
                        <p key={`item-${index}`} style={{ color: entry.color }}>
                            {unit} {formattedValue}
                        </p>
                    );
                })}
            </div>
        );
    }

    return null;
};





const Pachten = () => {
    
    const lastYear = new Date().getFullYear() - 1;
    const currentYear = new Date().getFullYear();
    
    
    // Pachtsumme Vorjahr
    const {
        loading: loadingPachtsummeLastYear,
        error: errorPachtsummeLastYear,
        data: dataPachtsummeOfLastYear,
    } = useQuery(GET_PACHTSUMME_OF_YEAR, {
        variables: { requestedYear: lastYear },
    });

    
    // Pachtsumme akutelles Jahr
    const {
        loading: loadingPachtsummeCurrentYear,
        error: errorPachtsummeCurrentYear,
        data: dataPachtsummeOfCurrentYear,
    } = useQuery(GET_PACHTSUMME_OF_YEAR, {
        variables: { requestedYear: currentYear },
    });


    // Erstattung Pacht Vorjahr
    const {
        loading: loadingErstattungPachtLastYear,
        error: errorErstattungPachtLastYear,
        data: dataErstattungPachtLastYear,
    } = useQuery(GET_ERSTATTUNG_PACHT_OF_YEAR, {
        variables: { requestedYear: lastYear },
    });


    // Erstattung Pacht aktuelles Jahr
    const {
        loading: loadingErstattungPachtCurrentYear,
        error: errorErstattungPachtCurrentYear,
        data: dataErstattungPachtCurrentYear,
    } = useQuery(GET_ERSTATTUNG_PACHT_OF_YEAR, {
        variables: { requestedYear: currentYear },
    });
    


    // Handle combined loading state
    if (loadingPachtsummeCurrentYear || loadingPachtsummeLastYear || loadingErstattungPachtLastYear || loadingErstattungPachtCurrentYear) {
        return <LoadingAnimation />;
    }

    // Handle combined error state
    if (errorPachtsummeCurrentYear || errorPachtsummeLastYear || errorErstattungPachtLastYear || errorErstattungPachtCurrentYear) {
        return (
            <div>
                {errorPachtsummeCurrentYear && <p>Error: {errorPachtsummeCurrentYear.message}</p>}
                {errorErstattungPachtCurrentYear && <p>Error: {errorErstattungPachtCurrentYear.message}</p>}
                {errorPachtsummeLastYear && <p>Error: {errorPachtsummeLastYear.message}</p>}
                {errorErstattungPachtLastYear && <p>Error: {errorErstattungPachtLastYear.message}</p>}
            </div>
        );
    }



    const pachtsummeOfLastYear = dataPachtsummeOfLastYear?.pachtsummeOfYear || 0;
    const erstattungPachtOfLastYear = dataErstattungPachtLastYear?.erstattungPachtOfYear || 0;
    const bereinigtePachtsummeOfLastYear = pachtsummeOfLastYear - erstattungPachtOfLastYear;
    
    const pachtsummeOfCurrentYear = dataPachtsummeOfCurrentYear?.pachtsummeOfYear || 0;
    const erstattungPachtOfCurrentYear = dataErstattungPachtCurrentYear?.erstattungPachtOfYear || 0;
    const bereinigtePachtsummeOfCurrentYear = pachtsummeOfCurrentYear - erstattungPachtOfCurrentYear;
    
    


    // Construct array
    const pachtsummen = [];
    pachtsummen.push({"jahr": lastYear, "Pachtsumme(EUR)": bereinigtePachtsummeOfLastYear});
    pachtsummen.push({"jahr": currentYear, "Pachtsumme(EUR)": bereinigtePachtsummeOfCurrentYear});



    return (
        
            <Paper sx={{ p: 6, m:0, width: '45%', display: 'inline-block', flex: '0 0 575px' }}>
                <Title>Pachtrechnungen</Title>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={pachtsummen}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="jahr" />
                        <YAxis />
                        <Tooltip content={<CustomTooltipPachtsummen />} />
                        <Legend />
                        <Bar dataKey="Pachtsumme(EUR)" fill="#003300" />
                    </BarChart>
                </ResponsiveContainer>
            </Paper>

    );
};

export default Pachten;
