import React from 'react';
import { useQuery } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Title from '../Utils/Title';

import { GET_GESAMTVERBRAEUCHE } from './graphql';

import LoadingAnimation from '../Utils/LoadingAnimation';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';



// Custom Tooltip Gesamtverbräuche
const CustomTooltipGesamtverbraeuche = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ background: 'white', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                <p>Gesamtverbräuche in {label}</p>
                {payload.map((entry, index) => {
                    let unit = '';
                    if (entry.name === 'Strom') unit = 'kWh';
                    if (entry.name === 'Gas' || entry.name === 'Wasser') unit = 'm³';

                    // Format value with thousands separator
                    const formattedValue = new Intl.NumberFormat('de-DE').format(entry.value);

                    return (
                        <p key={`item-${index}`} style={{ color: entry.color }}>
                            {entry.name}: {formattedValue} {unit}
                        </p>
                    );
                })}
            </div>
        );
    }

    return null;
};




const Gesamtverbraeuche = () => {

    // Gesamtverbräuche
    const {
        loading: loadingGesamtverbraeuche,
        error: errorGesamtverbraeuche,
        data: dataGesamtverbraeuche,
    } = useQuery(GET_GESAMTVERBRAEUCHE);
    

    if (loadingGesamtverbraeuche) {
        return <LoadingAnimation />;
    }

    if (errorGesamtverbraeuche) {
        return (
            <div>
                {errorGesamtverbraeuche && <p>Error: {errorGesamtverbraeuche.message}</p>}
            </div>
        );
    }


    const gesamtverbraeuche = dataGesamtverbraeuche?.gesamtverbraeuche?.map(item => ({
        name: item.abrechnungsperiode,
        Strom: item.gesamtverbrauchStrom,
        Gas: item.gesamtverbrauchGas,
        Wasser: item.gesamtverbrauchWasser,
    })) || [];



    return (

            <Paper sx={{ p: 6, display: 'flex', flexDirection: 'column', flex: '0 0 1200px' }}>
                <Title>Gesamtverbräuche</Title>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={gesamtverbraeuche}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltipGesamtverbraeuche />} />
                        <Legend />
                        <Bar dataKey="Strom" fill="#cc3300" />
                        <Bar dataKey="Gas" fill="#336699" />
                        <Bar dataKey="Wasser" fill="#0033cc" />
                    </BarChart>
                </ResponsiveContainer>
            </Paper>

    );
};

export default Gesamtverbraeuche;
