import React from 'react';
import Container from '@mui/material/Container';

import Gesamtverbraeuche from './Gesamtverbraeuche';
import Pachten from './Pachten';
import Nebenkosten from './Nebenkosten';


const Statistiken = () => {

    return (
        <Container 
            maxWidth="xl"
            sx={{ 
                display: 'flex',
                flexWrap: 'wrap',
                gap: '50px',
                justifyContent: 'center',
                p: '50px',
            }}
        >

            <Gesamtverbraeuche />

            <Pachten />

            <Nebenkosten />

        </Container>
    );
};

export default Statistiken;
