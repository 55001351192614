
import * as React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../Utils/Title'

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItemText from '@mui/material/ListItemText';
import { Link } from "react-router-dom";

const currentYear = new Date().getFullYear();
const lastYear = currentYear - 1;


function generate(element) {
    return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
        key: value,
    }),
    );
}

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));



export default function Uebersicht() {


    return (
        <Container maxWidth='md' sx={{ mt: 5, mb: 5 }}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Title>Listenübersicht</Title>



                        <Grid item xs={12} md={6}>
                            <Demo>
                                <List>

                                    <Link to={`/listen/telefonnummern`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Telefonnummern"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/adressen`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Adressen"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/standorte`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Standorte"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/eichdaten/strom`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Eichdaten Strom"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/eichdaten/wasser`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Eichdaten Wasser"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/eichdaten/gas`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Eichdaten Gas"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/geplante-gasdruckpruefungen`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Geplante Gasdruckprüfungen"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/faellige-gasdruckpruefungen`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Fällige Gasdruckprüfungen"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/auflagenfristen`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Auflagenfristen"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/briefkaesten`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Briefkästen"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/debitoren`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Debitoren"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/kennzeichen`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="KFZ-Kennzeichen"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/zaehlerstaende`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Zählerstände"
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/energieverbraeuche/${lastYear}`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`Energieverbräuche ${lastYear}`}
                                            />
                                        </ListItem>
                                    </Link>

                                    <Link to={`/listen/energieverbraeuche/${currentYear}`} style={{ textDecoration: 'none' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ListAltIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`Energieverbräuche ${currentYear}`}
                                            />
                                        </ListItem>
                                    </Link>

                                </List>
                            </Demo>
                        </Grid>




                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}




