import { gql } from '@apollo/client';

// Individuelle, für die Statistiken benötigte queries


// Für Gasdruckprüfungen gehen wir über Parzellen
export const GET_GESAMTVERBRAEUCHE = gql`
    query GetGesamtverbraeuche {
        gesamtverbraeuche {
            abrechnungsperiode
            gesamtverbrauchGas
            gesamtverbrauchStrom
            gesamtverbrauchWasser
        }
    }
`;

// Summe Pachtrechnungen
export const GET_PACHTSUMME_OF_YEAR = gql`
    query GetPachtsummeOfYear($requestedYear: Int!) {
        pachtsummeOfYear(requestedYear: $requestedYear)
    }
`;


// Summe Nebenkostenrechnungen
export const GET_NEBENKOSTENSUMME_OF_YEAR = gql`
    query GetNebenkostensummeOfYear($requestedYear: Int!) {
        nebenkostensummeOfYear(requestedYear: $requestedYear)
    }
`;


// Erstattete Pachten
export const GET_ERSTATTUNG_PACHT_OF_YEAR = gql`
    query GetErstattungPachtOfYear($requestedYear: Int!) {
        erstattungPachtOfYear(requestedYear: $requestedYear)
    }
`;

